import { Box, Flex } from "@chakra-ui/react";
import { PrimaryHeadline } from "src/components/common/module-headlines";
import { Container, CONTAINER_PX } from "../../layout/container";
import { QuoteCard, IQuoteCardProps } from "./quote-card";

export const QUOTE_FLEX_BASIS = ["100%", "480px", "550px", "700px"];
export const QUOTE_PADDING_LEFT = 3;

export const QuoteGrayBackground = () => (
    <Box w={["100%", "345px", "437px", "540px"]} h={["44%", "100%"]} bg="gray.50" pos="absolute" bottom={0} right={0} zIndex="-1" />
);

export interface ISingleQuoteModuleProps {
    title: string;
    titleAsH1: boolean;
    quoteCard: IQuoteCardProps;
}

export const SingleQuoteModule = ({ title, titleAsH1, quoteCard }: ISingleQuoteModuleProps) => (
    <Container pt={[0, 7, 10]} pb={[7, 7, 10]} pos="relative">
        <QuoteGrayBackground />
        <Flex flexDir={["column", "row"]} align="center">
            {title && (
                <PrimaryHeadline mb={[3, 0]} mr={[0, 3, 5, 6]} textAlign={["center", "left"]} titleAsH1={titleAsH1}>
                    {title}
                </PrimaryHeadline>
            )}

            {/* Dev-Note: the padding left ensures that single quotes and multi quotes are aligned with each other */}
            <Box pl={[0, ...CONTAINER_PX.slice(1).map(val => val + QUOTE_PADDING_LEFT)]} flexShrink={0} flexBasis={QUOTE_FLEX_BASIS}>
                <QuoteCard {...quoteCard} mr={[null, null, null, 10]} />
            </Box>
        </Flex>
    </Container>
);
