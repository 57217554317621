import fetchRetry from "fetch-retry";
import nodeFetch from "node-fetch";
import { MAX_ATTEMPTS, RETRY_DELAY_MS } from "src/consts/retry";
import { logger } from "../logger-util";

const HTTP_ERROR_STATUS_CODE = 404;

export const fetchWithRetry = fetchRetry(nodeFetch, {
    retryDelay: RETRY_DELAY_MS,
    retryOn: function (attempt, error, response) {
        if (attempt >= MAX_ATTEMPTS) {
            return false;
        }

        // Retry on network errors or on 4xx or 5xx status codes
        const shouldRetry = error !== null || (response !== null && response.status > HTTP_ERROR_STATUS_CODE);
        if (shouldRetry) {
            logger.warn({ message: `Retrying on Nextjs page creation ${response?.url ?? ""}, attempt number ${attempt + 1}`, error });
        }

        return shouldRetry;
    },
});
