import { ReactElement, useContext } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { IWikifolioComplement } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { DescriptionDefinition } from "src/components/base";
import { MixEtfWikifolioRangeContext } from "src/components/modules/mix-etf-with-wikifolio-module/context/mix-etf-wikifolio-range-context";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";

export const VALUE_DISPLAY_VARIANT = {
    annualizedPerformance: "annualizedPerformance",
    annualizedPerformanceChange: "annualizedPerformanceChange",
    annualizedVolatility: "annualizedVolatility",
    annualizedVolatilityChange: "annualizedVolatilityChange",
} as const;

interface IComplementValueDisplay {
    complement: IWikifolioComplement;
    variant: (typeof VALUE_DISPLAY_VARIANT)[keyof typeof VALUE_DISPLAY_VARIANT];
    label?: (value: number) => ReactElement;
}

export const ComplementValueDisplay = ({ complement, variant, label }: IComplementValueDisplay) => {
    const context = useContext(MixEtfWikifolioRangeContext);
    const { formatPercent, formatPerformance, formatVolatility } = useNumberFormatter();

    const index = Math.floor(context.sliderRange / 10) - 1;
    const value = Number(complement.portfolioScenarios[index][variant].toFixed(3));

    const formattedValue = (() => {
        if (variant === VALUE_DISPLAY_VARIANT.annualizedPerformanceChange || variant === VALUE_DISPLAY_VARIANT.annualizedVolatilityChange) {
            return formatPercent(value, 1).replace("-", "");
        }

        if (variant === VALUE_DISPLAY_VARIANT.annualizedVolatility) {
            return formatVolatility(value, 1);
        }

        return formatPerformance(value, 1);
    })();

    const getTextColor = (): TextProps["color"] => {
        if (variant === VALUE_DISPLAY_VARIANT.annualizedVolatilityChange) {
            return value <= 0 ? "green.600" : "red.600";
        }

        if (variant === VALUE_DISPLAY_VARIANT.annualizedPerformanceChange) {
            return value >= 0 ? "green.600" : "red.600";
        }

        return undefined;
    };

    const getTextWeight = (): TextProps["fontWeight"] => {
        if (variant === VALUE_DISPLAY_VARIANT.annualizedPerformanceChange || variant === VALUE_DISPLAY_VARIANT.annualizedVolatilityChange) {
            return "bold";
        }

        return "normal";
    };

    return (
        <>
            {label?.(value)}
            <Text as={DescriptionDefinition} fontWeight={getTextWeight()} fontSize="md" color={getTextColor()}>
                {formattedValue}
            </Text>
        </>
    );
};
