import { RichText } from "src/components/common/rich-text/rich-text";
import { ContainerWallOfText } from "src/components/layout/container";

export interface IWallOfTextModuleProps {
    description: string;
}

export const WallOfTextModule = ({ description }: IWallOfTextModuleProps) => (
    <ContainerWallOfText>
        <RichText text={description} />
    </ContainerWallOfText>
);
