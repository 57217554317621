const headerModuleColorSchemeMap = {
    white: {
        bg: "white",
    },
    gray: {
        bg: "gray.50",
    },
};

export type HeaderModuleColorScheme = keyof typeof headerModuleColorSchemeMap;

export const HEADER_MODULE_DEFAULT_COLOR_SCHEME: HeaderModuleColorScheme = "white";

export const getHeaderModuleColorScheme = (key?: HeaderModuleColorScheme) => headerModuleColorSchemeMap[key ?? HEADER_MODULE_DEFAULT_COLOR_SCHEME];
