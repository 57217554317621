import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";

export interface IDisclaimerModuleProps {
    description: string;
}

export const DisclaimerModule = (props: IDisclaimerModuleProps) => (
    <Container>
        <SimpleRichText text={props.description} fontSize="xs" borderY="1px" borderColor="gray.100" py={2} />
    </Container>
);
