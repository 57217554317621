import { Skeleton } from "@chakra-ui/react";

export const MarketoSkeleton = () => {
    return (
        <>
            <Skeleton h={3} mb={1} w="88px" rounded="4px" />
            <Skeleton h={6} mb={3} rounded="8px" />
            <Skeleton h={3} mb={1} w="132px" rounded="8px" />
            <Skeleton h={6} mb={3} rounded="8px" />
            <Skeleton h={3} mb={1} w="129px" rounded="8px" />
            <Skeleton h={6} mb={3} rounded="8px" />
            <Skeleton h={6} w="144px" rounded="4px" />
        </>
    );
};
