import { SystemStyleObject, useStyleConfig } from "@chakra-ui/react";
import { useSimpleRichText } from "src/components/common/rich-text/use-simple-rich-text";
import Button from "src/theme/components/button";

const svgArrowLink = (color: "green" | "gray") => {
    const _color = color === "green" ? "rgb(120, 166, 0)" : "rgb(51, 51, 51)";
    return `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='100%' height='100%'><g fill='none' fill-rule='evenodd' transform='translate(0 -3)'><path d='M0 0h24v24H0z'></path><g stroke='${_color}' strokeLinecap='round' stroke-linejoin='round' stroke-width='2'><path d='M19 12H4M16 18l4-6-4-6'></path></g></g></svg>`;
};
const getIconRule = (svgStr: string): SystemStyleObject => ({
    _after: {
        content: `url("data:image/svg+xml;utf8,${svgStr}")`,
        display: "inline-block",
        boxSize: "1em",
        alignSelf: "center",
        ml: 0.5,
        verticalAlign: "middle",
    },
});

interface IStyles {
    arrowLinkGray: SystemStyleObject;
    arrowLinkGreen: SystemStyleObject;
    table: SystemStyleObject;
}
const getSx = ({ arrowLinkGray, arrowLinkGreen, table }: IStyles): SystemStyleObject => ({
    h2: {
        fontSize: "3xl",
        fontWeight: "bold",
        mb: 1,
    },
    h3: {
        fontSize: "2xl",
        fontWeight: "bold",
        mb: 1,
    },
    h4: {
        fontSize: "md",
        fontWeight: "bold",
    },
    "* + h2, * + h3": {
        mt: 5,
    },
    "a.arrow-link--gray": {
        ...arrowLinkGray,
        ...getIconRule(svgArrowLink("gray")),
    },
    "a.arrow-link--green": {
        ...arrowLinkGreen,
        ...getIconRule(svgArrowLink("green")),
    },
    ".button": {
        ...Button.baseStyle,
        ...Button.sizes.md,
        "&--green": {
            ...Button.variants.solid({ colorScheme: "green" }),
        },
        "&--gray": {
            ...Button.variants.solid({ colorScheme: "gray" }),
        },
        "&--outline": {
            ...Button.variants.outline({ colorScheme: "gray" }),
        },
    },
    table,
    ".u-scroll-x": {
        my: 4,
        overflowX: "auto",
    },
    '[id^="anchor"]': {
        paddingTop: "100px",
        marginTop: "-100px",
    },
});

export const useRichText = (): SystemStyleObject => {
    const simpleSx = useSimpleRichText();
    const arrowLinkGray = useStyleConfig("Link", { variant: "ghost", colorScheme: "gray" });
    const arrowLinkGreen = useStyleConfig("Link", { variant: "ghost", colorScheme: "green" });
    const table = useStyleConfig("Table", { variant: "simple" });

    return {
        ...simpleSx,
        ...getSx({ arrowLinkGray, arrowLinkGreen, table }),
    };
};
