import { Box, Stack, Text, TextProps } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { WfAccordion } from "src/components/base/wf-accordion";
import { headingFontSizeMap } from "src/components/base/wf-heading";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { ILabelValue, ILink } from "src/types/common";

const ValueLine = (props: TextProps) => <Text {...props} sx={{ strong: headingFontSizeMap["5xl"] }} />;

interface ICard {
    valueLine: string;
    title: string;
    description: string;
}
const Card = ({ valueLine, title, description }: ICard) => (
    <Box flex={1} border="2px" borderColor="gray.300" rounded="8px" p={3}>
        <ValueLine mb={2} dangerouslySetInnerHTML={{ __html: valueLine }} />
        <Text fontSize={["md", "md", "xl"]} mb={1}>
            <strong>{title}</strong>
        </Text>
        <Text fontSize="sm">{description}</Text>
    </Box>
);

export interface IFeesModuleProps {
    title: string;
    cards: ICard[];
    questionAnswers: ILabelValue[];
    link: ILink;
}

export const FeesModule = ({ title, cards, questionAnswers, link }: IFeesModuleProps) => (
    <Container px={[null, null, null, 15]}>
        <ModulePrimaryHeadline textAlign={["left", "center"]} mb={[5, 5, 7]}>
            {title}
        </ModulePrimaryHeadline>
        <Stack direction={["column", "row"]} spacing={[3, 4, 5]} mb={5}>
            {cards.map((card, i) => (
                <Card key={i} {...card} />
            ))}
        </Stack>
        <WfAccordion data={questionAnswers} mb={[4, 6]} />
        <Box textAlign={["left", "right"]}>
            <ArrowLink href={link.url} target={link.target} colorScheme="green">
                {link.name}
            </ArrowLink>
        </Box>
    </Container>
);
