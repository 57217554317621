import { useState } from "react";
import { Box, Checkbox, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { FilledCircle } from "src/components/base/filled-circle";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { WfAlertDialog } from "src/components/modals/wf-alert-dialog/wf-alert-dialog";
import { ChallengeDropdown } from "src/components/modules/challenge-form-module/components/challenge-dropdown";
import { ChallengeFormSuccessMessage } from "src/components/modules/challenge-form-module/components/challenge-form-success-message";

export interface IChallengeFormModuleProps {
    title: string;
    subHeading: string;
    valid: boolean;
    validUntilText: string;
    firstInputLabel: string;
    firstInputDescription: string;
    firstInputPlaceholder: string;
    firstInputOptions: string[];
    firstInputEmptyText: string;
    secondInputLabel: string;
    secondInputDescription: string;
    secondInputPlaceholder: string;
    secondInputOptions: string[];
    secondInputEmptyText: string;
    checkboxLabel: string;
    requiredLabel: string;
    submitLabel: string;
    confirmModalHeader: string;
    confirmModalBody: string;
    confirmModalButtonLabel: string;
    successHeading: string;
    successSubHeading: string;
    successFirstButtonLabel: string;
    successFirstButtonUrl: string;
    successSecondButtonLabel: string;
    successSecondButtonUrl: string;
    successListItems: string[];
}

export const ChallengeFormModule = ({
    valid,
    title,
    subHeading,
    validUntilText,
    firstInputLabel,
    firstInputDescription,
    firstInputPlaceholder,
    firstInputOptions,
    firstInputEmptyText,
    secondInputLabel,
    secondInputDescription,
    secondInputPlaceholder,
    secondInputOptions,
    secondInputEmptyText,
    checkboxLabel,
    requiredLabel,
    submitLabel,
    confirmModalHeader,
    confirmModalBody,
    confirmModalButtonLabel,
    successHeading,
    successSubHeading,
    successFirstButtonLabel,
    successFirstButtonUrl,
    successSecondButtonLabel,
    successSecondButtonUrl,
    successListItems,
}: IChallengeFormModuleProps) => {
    const [firstInputValue, setFirstInputValue] = useState<string | null>(null);
    const [secondInputValue, setSecondInputValue] = useState<string | null>(null);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const confirmDialog = useDisclosure();

    return isFormSubmitted ? (
        <ChallengeFormSuccessMessage
            heading={successHeading}
            subHeading={successSubHeading}
            firstButtonLabel={successFirstButtonLabel}
            firstButtonUrl={successFirstButtonUrl}
            secondButtonLabel={successSecondButtonLabel}
            secondButtonUrl={successSecondButtonUrl}
            listItems={successListItems}
        />
    ) : (
        <Container my={10}>
            <VStack justifyContent="center" textAlign="center">
                <ModulePrimaryHeadline>{title}</ModulePrimaryHeadline>
                <Text mt={2} fontSize="lg" maxWidth="862px">
                    {subHeading}
                </Text>
                <HStack mt={2} spacing={1} alignItems="baseline" w="100%" justifyContent="center">
                    <FilledCircle position="relative" top="2px" flexShrink="0" size={2} color={valid ? "green.600" : "gray.300"} />
                    <Text textAlign="left" fontSize="md">
                        {validUntilText}
                    </Text>
                </HStack>
                <Box w="100%" maxWidth="620px" mt={5} textAlign="left">
                    <Box>
                        <Text fontSize="lg" fontWeight="bold">
                            {firstInputLabel}
                        </Text>
                        <Text mb={2} mt={1} fontSize="sm" color="gray.600">
                            {firstInputDescription}
                        </Text>
                        <ChallengeDropdown
                            isDisabled={!valid}
                            options={firstInputOptions}
                            value={firstInputValue}
                            onChange={setFirstInputValue}
                            placeholder={firstInputPlaceholder}
                            emptyText={firstInputEmptyText}
                        />
                    </Box>
                    <Box mt={4}>
                        <Text fontSize="lg" fontWeight="bold">
                            {secondInputLabel}
                        </Text>
                        <Text mb={2} mt={1} fontSize="sm" color="gray.600">
                            {secondInputDescription}
                        </Text>
                        <ChallengeDropdown
                            isDisabled={!valid}
                            options={secondInputOptions}
                            value={secondInputValue}
                            onChange={setSecondInputValue}
                            placeholder={secondInputPlaceholder}
                            emptyText={secondInputEmptyText}
                        />
                    </Box>
                    <Box>
                        <Checkbox
                            isDisabled={!valid}
                            mt={4}
                            sx={{ a: { textDecoration: "underline" } }}
                            onChange={e => setIsCheckboxSelected(e.target.checked)}
                        >
                            <Span dangerouslySetInnerHTML={{ __html: checkboxLabel }} />
                        </Checkbox>
                    </Box>
                    <Text fontSize="md" my={4}>
                        {requiredLabel}
                    </Text>
                    <Button
                        onClick={() => confirmDialog.onOpen()}
                        colorScheme="green"
                        isDisabled={!firstInputValue || !secondInputValue || !isCheckboxSelected || !valid}
                    >
                        {submitLabel}
                    </Button>
                </Box>
            </VStack>
            <WfAlertDialog
                {...confirmDialog}
                onConfirm={() => {
                    setIsFormSubmitted(true);
                    confirmDialog.onClose();
                }}
                confirmButtonText={confirmModalButtonLabel}
                header={confirmModalHeader}
                body={<Text whiteSpace="pre-line">{confirmModalBody}</Text>}
            />
        </Container>
    );
};
