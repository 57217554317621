import { Flex, Stack, StackDivider } from "@chakra-ui/react";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfLink } from "src/components/base/wf-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { ILink, IImage } from "src/types/common";

const IMAGE_WIDTH = ["100%", "277px", "382px", "420px"];

interface IPartnerListItem {
    description: string;
    image: IImage;
    link: ILink;
}

const PartnerListItem = ({ description, link, image }: IPartnerListItem) => (
    <Flex w="100%" align={["flex-start", "center"]} flexDir={["column", "row-reverse"]}>
        <Flex minW={IMAGE_WIDTH} maxW={IMAGE_WIDTH} justify={["left", "center"]}>
            <WfLink href={link.url} target={link.target}>
                <ResponsiveImage
                    w="auto"
                    h={["59px", "51px", "70px", "84px"]}
                    srcSet={[{ queryParams: { h: 59 } }, { queryParams: { h: 51 } }, { queryParams: { h: 70 } }, { queryParams: { h: 84 } }]}
                    baseUrl={image.url}
                    alt={image.alt}
                />
            </WfLink>
        </Flex>
        <SimpleRichText flexGrow={1} mr={[0, 2]} mt={[3, 0]} fontSize={["md", "md", "lg"]} text={description} />
    </Flex>
);

export interface IPartnerListModuleProps {
    items: IPartnerListItem[];
}

export const PartnerListModule = (props: IPartnerListModuleProps) => (
    <Container>
        <Stack divider={<StackDivider borderColor="gray.200" />} spacing={[5, 5, 7]} pb={[5, 5, 7]}>
            {props.items.map((itemProps, index) => (
                <PartnerListItem key={index} {...itemProps} />
            ))}
        </Stack>
    </Container>
);
