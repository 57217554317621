import { ServerResponse } from "http";
import { GetServerSidePropsContext } from "next";
import { RequestInit, Response } from "node-fetch";
import { IBaseParam } from "src/types/common";
import { ErrorPageCode } from "src/types/layout";
import { getErrorPageCode, UnexpectedStatusCodeError } from "../error-page-utils";
import { logger } from "../logger-util";
import { fetchWithRetry } from "./fetch-with-retry";

const API_URL = process.env.SSR_UMBRACO_API_ENDPOINT;

// helper function to fetch data from umbraco backend including all necessary data
export async function fetchUmbraco(path: string, context: GetServerSidePropsContext<IBaseParam>, _options?: RequestInit) {
    const { req, query } = context;
    const url = new URL(`${API_URL}/${path}`);

    url.searchParams.append("language", context.locale as string);

    // append query params to umbraco final url
    Object.entries(query).forEach(([paramName, param]) => {
        if (typeof param === "string") {
            url.searchParams.append(paramName, param);
        }
    });

    const userAgent = req.headers["user-agent"] ? { "User-Agent": req.headers["user-agent"] } : {};
    const referer = req.headers["referer"] ? { Referer: req.headers["referer"] } : {};
    const forwardedFor = req.headers["x-forwarded-for"] ? { "X-Forwarded-For": req.headers["x-forwarded-for"] } : {};

    const options = Object.assign(
        {
            headers: {
                Accept: "application/json",
                Cookie: req.headers.cookie || "",
                ...userAgent,
                ...referer,
                ...forwardedFor,
                "X-WF-Tracking-Param": `/${context.locale}${req.url}`,
            },
        } as RequestInit,
        _options
    );

    const res = await fetchWithRetry(url.href, options);

    forwardCookies(res, context.res);

    let errorPageCode: ErrorPageCode | null = null;
    try {
        errorPageCode = getErrorPageCode(res.status);
    } catch (error) {
        if (error instanceof UnexpectedStatusCodeError) {
            logger.error({ message: error.message, url: url.href, error });
        }
    }

    if (errorPageCode) {
        context.res.statusCode = errorPageCode;
    }

    const data = res.status !== 500 ? await res.json() : {};

    return { ...data, errorPageCode };
}

interface IHeadersRaw extends Headers {
    raw: () => {
        "set-cookie": readonly string[];
    };
}

function forwardCookies(fetchResponse: Response, nextResponse: ServerResponse) {
    // Workaround: https://github.com/node-fetch/node-fetch/issues/251#issuecomment-428143940
    const cookieArr = (fetchResponse.headers as unknown as IHeadersRaw).raw()["set-cookie"];

    if (cookieArr) {
        nextResponse.setHeader("set-cookie", cookieArr);
    }
}
