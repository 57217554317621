import { Box, BoxProps, Flex, Grid, useDisclosure, VStack } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { ButtonLink } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconPlay } from "src/components/icons";
import { Container } from "src/components/layout/container";
import { VideoModal } from "src/components/modals/video-modal/video-modal";
import { ILink, ITitleAndDescription } from "src/types/common";

const defaultPseudoBubbleProps = {
    "::before, ::after": {
        content: '""',
        position: "absolute",
        display: "block",
        rounded: "50%",
    },
    "::before": { bg: "gray.100", boxSize: 5 },
    "::after": { bg: "gray.200", boxSize: "20px" },
};

type IBeforeAfter = Pick<BoxProps, "bottom" | "left" | "_before" | "_after">;
const pseudoElementMap: { [key: number]: IBeforeAfter } = {
    1: { bottom: "4px", left: "58px", _before: { bottom: "8px", left: "-56px" }, _after: { bottom: "-20px", left: "-5px" } },
    2: { bottom: "52px", left: "8px", _before: { bottom: "90px", left: "120px" }, _after: { bottom: "130px", left: "100px" } },
    3: { bottom: "30px", left: "6px", _before: { bottom: "104px", left: "2px" }, _after: { bottom: "86px", left: "-8px" } },
};

const Bubble = ({ no }: Pick<IStepProps, "no">) => (
    <Box
        pos="absolute"
        display={["none", "initial"]}
        boxSize={13}
        border="4px"
        borderColor="gray.800"
        rounded="full"
        zIndex={-1}
        sx={defaultPseudoBubbleProps}
        {...pseudoElementMap[no]}
    />
);

interface IStepProps extends ITitleAndDescription {
    no: number;
}
const Step = ({ no, title, description }: IStepProps) => (
    <Flex align="flex-start">
        <Box
            pos={["absolute", "relative"]}
            w={["auto", "182px"]}
            fontSize={["140px", "200px"]}
            fontWeight="heavy"
            lineHeight="none"
            color="green.300"
            textAlign="right"
            userSelect="none"
        >
            <Bubble no={no} />
            {no}
        </Box>
        <Box zIndex={1} pt={[12, 8]} w={["100%", "370px"]} ml={[0, 1]}>
            <WfHeading as="h3" mb={[0.5, 1]} fontSize={["xl", "2xl"]}>
                {title}
            </WfHeading>
            <SimpleRichText text={description} />
        </Box>
    </Flex>
);

const templateAreas = [
    // mob
    `
    "header header"
    "link   link"
    "steps  steps"
    `,
    // tab
    `
    "header header"
    "link   link"
    "steps  steps"
    `,
    // DS
    `
    "header header"
    "link   steps"
    ".      steps"
    `,
    // DB
    `
    "header steps"
    "link   steps"
    ".      steps"
    `,
];

export interface IStepsModuleProps {
    title: string;
    link?: ILink;
    video?: {
        title: string;
        url: string;
        linkText: string;
    };
    steps: ITitleAndDescription[];
}

export const StepsModule = ({ title, link, video, steps }: IStepsModuleProps) => {
    const videoModal = useDisclosure();

    return (
        <Container>
            <Grid templateColumns="1fr auto" templateRows="auto auto 1fr" templateAreas={templateAreas}>
                <ModulePrimaryHeadline gridArea="header" w={["100%", "100%", "442px", "544px"]} mb={5}>
                    {title}
                </ModulePrimaryHeadline>
                {(link || video) && (
                    <VStack gridArea="link" align="flex-start" spacing={[3, 2, 4]} mb={[5, 6]}>
                        {link && (
                            <ArrowLink href={link.url} target={link.target} colorScheme="green" fontSize={["lg", "lg", "xl"]}>
                                {link.name}
                            </ArrowLink>
                        )}
                        {video && (
                            <>
                                <ButtonLink onClick={videoModal.onOpen} variant="ghost" fontSize={["lg", "lg", "xl"]}>
                                    {video.linkText}
                                    <IconPlay ml={0.5} />
                                </ButtonLink>
                                <VideoModal title={video.title} youtubeLink={video.url} {...videoModal} />
                            </>
                        )}
                    </VStack>
                )}
                <VStack gridArea="steps" align="flex-start" spacing={[4, 10]}>
                    {steps.slice(0, 5).map((step, i) => (
                        <Step key={i} no={i + 1} {...step} />
                    ))}
                </VStack>
            </Grid>
        </Container>
    );
};
