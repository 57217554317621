// eslint-disable-next-line no-restricted-imports
import { Avatar, Box, BoxProps, Button, HStack, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { TUnderlying } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { WfLink, WfLinkUnstyled } from "src/components/base/wf-link";
import { EtfSearch } from "src/components/modules/mix-etf-with-wikifolio-module/components/search/etf-search";
import { useLinkBuilder } from "src/hooks/use-link-builder";

const SectionSkeleton = () => (
    <>
        <HStack>
            <SkeletonCircle flexShrink={0} size="7" />
            <Box>
                <Skeleton w={["215px", "288px"]} h={2} mb={1} />
                <Skeleton w="105px" h={2} />
            </Box>
        </HStack>
        <Skeleton w="156px" h="40px" />
    </>
);

interface IStep1Section extends BoxProps {
    onReset: () => void;
    onSelectedUnderlyingIsin: (isin: string | null) => void;
    sectionTitle: string;
    isLoading: boolean;
    selectedUnderlyingIsin: string | null;
    searchPlaceholder: string;
    underlying?: TUnderlying;
}

export const Step1Section = ({
    isLoading,
    sectionTitle,
    underlying,
    selectedUnderlyingIsin,
    searchPlaceholder,
    onSelectedUnderlyingIsin,
    onReset,
    ...boxProps
}: IStep1Section) => {
    const { t } = useTranslation("mix-etf-with-wikifolio-module");
    const { buildUnderlyingLink } = useLinkBuilder();
    const underlyingLink = buildUnderlyingLink(underlying?.isin ?? "");

    let content;

    if (isLoading) {
        content = <SectionSkeleton />;
    } else if (selectedUnderlyingIsin) {
        content = (
            <>
                <HStack gap={1}>
                    <WfLinkUnstyled href={underlyingLink}>
                        <Avatar
                            borderColor="lilac.600"
                            borderWidth="2px"
                            variant="grayOutline"
                            size="lg"
                            name={underlying?.shortDescription}
                            src={underlying?.imageUrl}
                            loading="eager"
                        />
                    </WfLinkUnstyled>
                    <Box>
                        <WfLink href={underlyingLink} variant="unstyled">
                            <Text fontSize={["md", "xl"]} color="lilac.600" fontWeight="bold" noOfLines={1} wordBreak="break-all">
                                {underlying?.shortDescription}
                            </Text>
                        </WfLink>
                        <Text pt={[1, 0]} fontSize="sm">
                            {underlying?.isin}
                        </Text>
                    </Box>
                </HStack>

                <Button variant="ghost-underline" size="sm" onClick={onReset} flexShrink={0}>
                    {t("buttons.reset-selection")}
                </Button>
            </>
        );
    } else {
        content = <EtfSearch placeholderText={searchPlaceholder} onSelect={underlying => onSelectedUnderlyingIsin(underlying.resourceIdentifier)} />;
    }

    return (
        <Box as="section" {...boxProps}>
            <Text fontSize="lg" fontWeight="bold" as="h3" pb={2}>
                {sectionTitle}
            </Text>

            <Box
                p={2}
                boxShadow="base"
                rounded="8px"
                borderRadius="8px"
                display="flex"
                bg="white"
                justifyContent="space-between"
                alignItems={["baseline", "center"]}
                flexFlow={["column", "row"]}
                gap={[2, 0]}
            >
                {content}
            </Box>
        </Box>
    );
};
