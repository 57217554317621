import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAuthModalStore } from "src/stores/auth-modal-store";

export const useReturnUrl = (returnUrl?: string) => {
    const router = useRouter();
    const { setReturnUrl } = useAuthModalStore();

    useEffect(() => {
        const configured = returnUrl;
        // DEV-Note: Query params are case-sensitive. -> Uppercase to have backwards
        // compatibility with Tartarus.
        const queryParam = router.query.ReturnUrl;

        if (!configured && !queryParam) {
            return;
        }

        // DEV-Note: Query Parameter can overrule the configured "Return Url"
        setReturnUrl((queryParam as string) ?? configured);
    }, [returnUrl, router.query.ReturnUrl, setReturnUrl]);
};
