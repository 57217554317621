import { useRef } from "react";
import { VStack, StackProps } from "@chakra-ui/react";
import { ArticlePreview, IArticlePreviewProps } from "src/components/common/article-preview/article-preview";
import { IArticlePreview } from "src/components/common/article-preview/article-preview.types";
import { ExpandableText } from "src/components/common/expandable-text";
import { LeaveWikifolioDialog } from "src/components/modals/leave-wikifolio-dialog/leave-wikifolio-dialog";
import { useLeaveWikifolio } from "src/hooks/use-leave-wikifolio";

export interface ICommentProps extends StackProps {
    articlePreview?: IArticlePreview;
    articleSize?: IArticlePreviewProps["size"];
    noOfLines?: number;
    message: string;
}

export const Comment = ({ articlePreview, articleSize, message, noOfLines = 4, ...stackProps }: ICommentProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { isOpen, onClose, onConfirm } = useLeaveWikifolio(ref);
    return (
        <VStack ref={ref} spacing={4} maxW="100%" width="100%" alignItems={"stretch"} {...stackProps}>
            <ExpandableText formattingType="userHtml" noOfLines={noOfLines} text={message} maxW="100%" />
            {articlePreview && <ArticlePreview articlePreview={articlePreview} size={articleSize} />}
            <LeaveWikifolioDialog isOpen={isOpen} onConfirm={onConfirm} onClose={onClose} />
        </VStack>
    );
};
