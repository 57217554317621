import chunk from "lodash.chunk";
import shuffle from "lodash.shuffle";

const fillArrayWith = <T>(arr: T[], maxLength: number, fillArr: T[], pickRandomly = true): T[] => {
    const newArray = [...arr, ...(pickRandomly ? shuffle(fillArr) : fillArr)];

    if (newArray.length >= maxLength) {
        return newArray.slice(0, maxLength);
    }

    return fillArrayWith(newArray, maxLength, fillArr, pickRandomly);
};

/**
 * Returns ALWAYS! equal chunked arrays.
 *
 * If the last chunk contains less items, then fill it up with items of prev. chunks (Default: randomly picked).
 */
export const chunkArrayEqually = <T>(arr: T[], chunkLength: number, pickRandomly = true) => {
    if (arr.length < chunkLength) {
        return [fillArrayWith(arr, chunkLength, arr, pickRandomly)];
    }

    const chunks = chunk(arr, chunkLength);

    const lastChunk = chunks[chunks.length - 1];
    if (lastChunk.length === chunkLength) {
        // array chunked exactly
        return chunks;
    }

    const previousChunks = chunks.slice(0, -1);
    const newLastChunk = fillArrayWith(lastChunk, chunkLength, previousChunks.flat(), pickRandomly);

    return [...previousChunks, newLastChunk];
};

export const removeDuplicates = <T extends string | number>(arr: ReadonlyArray<T>) => arr.filter((item, index) => arr.indexOf(item) === index);
