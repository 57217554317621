import { Box } from "@chakra-ui/react";
import Script from "next/script";
import { Container } from "src/components/layout/container";
import { useRouterLocale } from "src/utils/router/use-router-locale";

export interface ICookiebotDeclarationModule {}

export const CookiebotDeclarationModule = ({}: ICookiebotDeclarationModule) => {
    const { language } = useRouterLocale();
    return (
        <Container>
            <Box id="CookieDeclaration">
                <Script
                    data-culture={language}
                    id="CookieDeclaration"
                    src="https://consent.cookiebot.com/0f775456-4d25-45f4-be85-7e1b9a64c8b5/cd.js"
                    type="text/javascript"
                />
            </Box>
        </Container>
    );
};
