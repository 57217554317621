import { StrictMode } from "react";
import { Text } from "@chakra-ui/react";
import { WfHeading } from "src/components/base/wf-heading";
import { Container } from "src/components/layout/container";
import { StepsWrapper } from "./steps-wrapper";

export interface IMixEtfWithWikifolioModuleProps {
    title: string;
    description: string;
    step1Label: string;
    step2Label: string;
    step3Label: string;
    searchPlaceholder: string;
}

const MIX_ETF_WITH_WIKIFOLIO_MODULE_HEADING_TEST_ID = "mix-etf-with-wikifolio-module-heading-test-id";

export const MixEtfWithWikifolioModule = ({
    title,
    description,
    step1Label,
    step2Label,
    step3Label,
    searchPlaceholder,
}: IMixEtfWithWikifolioModuleProps) => {
    return (
        <StrictMode>
            <Container>
                <WfHeading as="h2" fontSize="4xl" data-test-id={MIX_ETF_WITH_WIKIFOLIO_MODULE_HEADING_TEST_ID}>
                    {title}
                </WfHeading>
                <Text py={2} fontSize="lg">
                    {description}
                </Text>
                <StepsWrapper step1Label={step1Label} step2Label={step2Label} step3Label={step3Label} searchPlaceholder={searchPlaceholder} />
            </Container>
        </StrictMode>
    );
};
