import { Box, Stack, useDisclosure, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ArrowLink } from "src/components/base/arrow-link";
import { PrimaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { GlobalNavSearch } from "src/components/layout/global-nav-search/global-nav-search";
import { GlobalTypeaheadWrapper } from "src/components/layout/global-nav-search/global-typeahead-wrapper";
import { ILink } from "src/types/common";

export interface ISearchModuleProps {
    /**  Text description of the module, comes on top of the search  */
    title: string;
    titleAsH1: boolean;
    link?: ILink;
    description?: string;
    placeholder?: string;
}

const GrayBackground = () => <Box w="100%" h="100%" bg="gray.50" pos={["absolute", "absolute", "initial"]} bottom={0} right={0} zIndex="-1" />;

/**  Search typeahead with dropdown selection, combined with a arrow button  */
export const SearchModule = ({ title, titleAsH1, link, description, placeholder }: ISearchModuleProps) => {
    const isMobileOrTablet = useBreakpointValue([true, true, false]);
    const { isOpen: isSearchOpen, onToggle: onSearchToggle, onClose: onSearchClose } = useDisclosure();
    const { t } = useTranslation("common");

    const renderOverlaySearch = isMobileOrTablet && isSearchOpen;

    const onSearchFocus = () => {
        if (isMobileOrTablet) {
            onSearchToggle();
        }
    };

    return (
        <>
            {renderOverlaySearch && (
                <GlobalNavSearch placement="area" navHeights={[0]} onSearchClose={onSearchClose} searchPlaceholderText={placeholder} />
            )}
            <Container>
                <PrimaryHeadline mb={[4, 4, 5]} titleAsH1={titleAsH1}>
                    {title}
                </PrimaryHeadline>
                {description && <SimpleRichText text={description} mb={[4, 4, 5]} />}
            </Container>
            <Box pos={["relative", "relative", "initial"]}>
                <GrayBackground />
                <Container>
                    <Stack
                        direction={["column", "column", "row"]}
                        py={[3, 4, 4, 5]}
                        px={[0, 0, 3, 5]}
                        spacing={[3, 3, 5]}
                        backgroundColor={["none", "none", "gray.50"]}
                    >
                        <Box flex={1}>
                            <GlobalTypeaheadWrapper
                                onFocus={onSearchFocus}
                                placeholderText={placeholder ?? t("typeahead-placeholder")}
                                placement="area"
                            />
                        </Box>

                        {link && (
                            <ArrowLink display="inline-block" href={link.url} target={link.target} alignSelf={["flex-start", "flex-start", "center"]}>
                                {link.name}
                            </ArrowLink>
                        )}
                    </Stack>
                </Container>
            </Box>
        </>
    );
};
