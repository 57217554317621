import { Box, Stack } from "@chakra-ui/react";
import { SingleCta } from "src/components/common/cta/cta";
import { Container } from "src/components/layout/container";
import { Bestseller } from "src/components/modules/bestseller-module/bestseller";
import { IBestsellerData } from "src/components/modules/bestseller-module/bestseller.types";
import { ITitleAndDescription } from "src/types/common";
import { ICta } from "src/types/cta";
import { ModulePrimaryHeadline } from "../../common/module-headlines";
import { SimpleRichText } from "../../common/rich-text/simple-rich-text";

export interface ISingleBestsellerProps extends ITitleAndDescription {
    cta: ICta;
    wikifolio: IBestsellerData | null;
}

export const SingleBestSellerModule = ({ title, description, cta, wikifolio }: ISingleBestsellerProps) =>
    wikifolio ? (
        <Container>
            <Stack direction={["column", "row"]} spacing={[3, 4, 5, 13]} justifyContent="space-between">
                <Box>
                    <ModulePrimaryHeadline>{title}</ModulePrimaryHeadline>
                    <SimpleRichText mt={3} fontSize={["md", "md", "lg", "lg"]} text={description} />
                    <Box mt="3" w={["100%", "auto"]}>
                        {cta && <SingleCta {...cta} />}
                    </Box>
                </Box>
                <Box alignSelf={["center", "baseline"]} display="flex" rounded="8px">
                    <Bestseller
                        buttonsDirection={["column", "column", "column", "row-reverse"]}
                        data={wikifolio}
                        w={["312px", "297px", "297px", "358px"]}
                    />
                </Box>
            </Stack>
        </Container>
    ) : null;
