import { createContext, ReactElement, useState } from "react";
import { DEFAULT_MIX_ETF_WIKIFOLIO_RANGE } from "src/components/modules/mix-etf-with-wikifolio-module/constants";

interface IMixEtfWikifolioRangeContext {
    sliderRange: number;
    setSliderRange: (sliderRange: number) => void;
}
export const MixEtfWikifolioRangeContext = createContext<IMixEtfWikifolioRangeContext>({
    sliderRange: DEFAULT_MIX_ETF_WIKIFOLIO_RANGE,
    setSliderRange: () => {},
});

interface IMixEtfWikifolioRangeContextProviderProps {
    children: ReactElement | ReactElement[];
}
export const MixEtfWikifolioRangeContextProvider = ({ children }: IMixEtfWikifolioRangeContextProviderProps) => {
    const [sliderRange, setSliderRange] = useState(DEFAULT_MIX_ETF_WIKIFOLIO_RANGE);
    return <MixEtfWikifolioRangeContext.Provider value={{ sliderRange, setSliderRange }}>{children}</MixEtfWikifolioRangeContext.Provider>;
};
