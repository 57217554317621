import { Box, BoxProps, useToken } from "@chakra-ui/react";
import { FullSizeFlex } from "src/components/base";

export const ChartLoadingState = ({ children, ...boxProps }: BoxProps) => {
    const [gray200] = useToken("colors", ["gray.200"]);

    return (
        <FullSizeFlex direction="column" justify="space-around" pl={5} bg="white" {...boxProps}>
            {[1, 2, 3, 4].map(key => (
                <Box
                    key={key}
                    backgroundImage={`linear-gradient(to right, ${gray200} 20%, white 0%)`}
                    backgroundPosition="bottom"
                    backgroundSize="10px 2px"
                    backgroundRepeat="repeat-x"
                    h="3px"
                />
            ))}
            {children}
        </FullSizeFlex>
    );
};
