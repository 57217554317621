import { useEffect, useState } from "react";
import { Box, Flex, FlexProps, Input, Text, VStack } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "next-i18next";
import { MotionBox } from "src/components/base";
import { Button } from "src/components/base/button";
import { HashLinkUnstyled } from "src/components/base/hash-link";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfHeading } from "src/components/base/wf-heading";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { ThirdPartyAuthButtons, EnumPlacement } from "src/components/common/auth";
import { HoverCard } from "src/components/common/hover-card";
import { Ranking } from "src/components/common/ranking/ranking";
import { EnumRankingType, IRankingData } from "src/components/common/ranking/types";
import { ScrollSnap, ScrollSnapItem } from "src/components/common/scroll-snap";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { Container, ContainerHeader, CONTAINER_PX_XS_SM } from "src/components/layout/container";
import { getGlobals } from "src/consts/globals";
import { ORGANIZATION_SCHEMA } from "src/consts/structured-data";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { useUserStore } from "src/stores/user-store";
import { IImage, ITitleAndDescription, IUser, IWikifolio } from "src/types/common";

export const SECTION_PB = ["32px", "48px", "48px", "74px"];

const cardPositionMap: { [key: number]: FlexProps } = {
    0: {
        position: [null, "absolute", "absolute"],
        left: [null, "16px", "24px", "-40px"],
        bottom: [null, "-" + SECTION_PB[1], "-" + SECTION_PB[2], "-45px"],
    },
    1: {
        position: [null, "absolute", "absolute"],
        left: [null, "auto", "auto", "314px"],
        right: [null, "16px", "24px", "auto"],
        bottom: [null, "166px", "174px", "-" + SECTION_PB[3]],
    },
    2: {
        position: [null, "absolute", "absolute"],
        right: [null, null, null, "24px"],
        bottom: [null, null, null, "169px"],
        display: ["flex", "none", "none", "flex"], // hide on TAB & DS
    },
};

interface IWikifolioData extends Pick<IWikifolio, "symbol" | "shortDescription"> {
    trader: IUser;
    ranking: IRankingData;
}

interface ICardProps extends IWikifolioData {
    index: number;
}

interface ILeftSideContainerProps extends ITitleAndDescription {
    welcomePageNodeId: number | null;
}

const Card = ({ trader, symbol, shortDescription, ranking, index }: ICardProps) => {
    const { buildWikifolioLink } = useLinkBuilder();
    const getUserName = useGetUserName();

    return (
        <HoverCard
            as={WfLinkUnstyled}
            href={buildWikifolioLink(symbol)}
            flexDir="column"
            w={["70vw", "314px"]}
            maxW="380px"
            {...cardPositionMap[index]}
        >
            <Flex align="center" mb={3}>
                <TraderAvatar size="xl" trader={trader} mr={1} />
                <Box overflow="hidden">
                    <Text fontSize="xl" fontWeight="bold" noOfLines={1} wordBreak="break-all">
                        {getUserName(trader)}
                    </Text>
                    <Text fontSize="sm" noOfLines={1} wordBreak="break-all">
                        {shortDescription}
                    </Text>
                </Box>
            </Flex>
            <Ranking size="lg" data={{ ...ranking, type: EnumRankingType.MaxLoss }} align="flex-start" />
        </HoverCard>
    );
};

const LeftSideContainer = ({ title, description, welcomePageNodeId }: ILeftSideContainerProps) => {
    const { t } = useTranslation("common");
    const { buildRegisterModalLink } = useLinkBuilder();
    const { prefilledRegisterEmail, setPrefilledRegisterEmail, setShowEmailRegisterForm, setWelcomePageNodeId } = useAuthModalStore();
    const loginProviders = getGlobals().loginProviders;
    const { isLoggedIn } = useUserStore();

    return (
        <Box flexShrink={0} w={["auto", "50%"]} pr={[0, 5, 5, 10]} py={[null, 7, 10]} pt={7} pb={3} zIndex={1} minHeight={[300, 746, 654]}>
            <WfHeading as="h1" fontSize={["5xl", "5xl", "6xl"]} mb={[3, 3, 4]} whiteSpace={["normal", "normal", "normal", "nowrap"]}>
                {title}
            </WfHeading>
            <Text fontSize={["md", "md", "xl"]} mb={[4, 4, 5]}>
                {description}
            </Text>
            {!isLoggedIn && (
                <VStack spacing={2}>
                    <Input
                        bg="white"
                        type="email"
                        placeholder={t("email")}
                        aria-label={t("email")}
                        value={prefilledRegisterEmail}
                        onChange={e => setPrefilledRegisterEmail(e.target.value)}
                    />
                    <Button
                        as={HashLinkUnstyled}
                        colorScheme="green"
                        width="100%"
                        className="gtm-register"
                        data-gtm-action="header-for-home"
                        href={buildRegisterModalLink()}
                        onClick={() => {
                            setShowEmailRegisterForm(true);
                            setWelcomePageNodeId(welcomePageNodeId);
                        }}
                    >
                        {t("register-for-free")}
                    </Button>
                    {loginProviders?.length && (
                        <ThirdPartyAuthButtons placement={EnumPlacement.HeaderForHome} loginProviders={loginProviders} width="100%" />
                    )}
                </VStack>
            )}
        </Box>
    );
};

const AnimatedImages = ({ images }: { images: IImage[] }) => {
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    useEffect(() => {
        if (images.length === 1) {
            return;
        }

        const intervalId = setInterval(() => setCurrentImgIndex(index => (index + 1) % images.length), 8000);

        return () => clearInterval(intervalId);
    });

    return (
        <AnimatePresence initial={false}>
            <MotionBox
                key={currentImgIndex}
                boxSize="100%"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                    position: "absolute",
                    opacity: 0,
                }}
                transition={{ bounce: 0, duration: 1 }}
            >
                <ResponsiveImage
                    objectFit="cover"
                    objectPosition="center"
                    height={["auto", "100%"]}
                    width={["100%"]}
                    maxWidth="none"
                    srcSet={[
                        { queryParams: { w: 360, h: 334, fit: "crop" } },
                        { queryParams: { w: 384 } },
                        { queryParams: { w: 496 } },
                        { queryParams: { w: 725 } },
                    ]}
                    baseUrl={images[currentImgIndex].url}
                    alt={images[currentImgIndex].alt}
                    loading="eager"
                />
            </MotionBox>
        </AnimatePresence>
    );
};

export interface IHeaderForHomeModuleProps {
    title: string;
    description: string;
    images: IImage[];
    wikifolios: IWikifolioData[];
    welcomePageNodeId: number | null;
}

export const HeaderForHomeModule = ({ title, description, images, wikifolios, welcomePageNodeId }: IHeaderForHomeModuleProps) => (
    <>
        <ContainerHeader pos="relative" bg="gray.50">
            <ContainerHeader overflow="hidden">
                <Container display="flex" flexDir={["column", "row"]} alignItems="stretch">
                    <LeftSideContainer title={title} description={description} welcomePageNodeId={welcomePageNodeId} />
                    <Box pos={["relative", "static"]} h={["334px", "auto"]} flexGrow={1} mx={[-3, 0]}>
                        <Box pos="absolute" top={0} right={0} h="100%" w={["100%", "50%"]}>
                            <AnimatedImages images={images} />
                        </Box>
                    </Box>
                </Container>
            </ContainerHeader>
            <ContainerHeader pos="absolute" w="100%" top={0} left={0} bottom={-14} pb={14} overflow="hidden" pointerEvents="none">
                <Box pos="absolute" right={0} bottom={[-2, 14]} w={["100%", "50%"]}>
                    <ScrollSnap
                        px={[...CONTAINER_PX_XS_SM, 0]}
                        py={[12, 0]}
                        gap={2}
                        overflowX={["auto"]}
                        templateColumns={["repeat(3, auto)"]}
                        pointerEvents="auto"
                    >
                        {wikifolios.map((wf, i) => (
                            <ScrollSnapItem key={i} pr={i === 2 ? [2, 2, 0] : undefined}>
                                <Card {...wf} index={i} />
                            </ScrollSnapItem>
                        ))}
                    </ScrollSnap>
                </Box>
            </ContainerHeader>
        </ContainerHeader>
        <script
            id="organization-schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(ORGANIZATION_SCHEMA),
            }}
        />
    </>
);
