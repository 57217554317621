import { useEffect } from "react";
import { chakra, useMultiStyleConfig, useStyleConfig, Text, useBoolean, SystemStyleObject } from "@chakra-ui/react";
import Head from "next/head";
import Script from "next/script";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { ContainerSmall } from "src/components/layout/container";
import { ITitleAndDescription } from "src/types/common";
import { MarketoSkeleton } from "./marketo-skeleton";

// this object will be available globally, created by marketo third party javascript
declare const MktoForms2: {
    loadForm: (baseUrl: string, munchkinId: string, formId: number) => void;
};

const selectCaret = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`;

const removeInvalidRule = ({ _invalid, ...style }: SystemStyleObject & { _invalid?: unknown }): SystemStyleObject => style;

interface IComponentStyle {
    inputStyle: SystemStyleObject;
    buttonStyle: SystemStyleObject;
    formErrorStyle: SystemStyleObject;
    formLabelStyle: SystemStyleObject;
    selectStyle: SystemStyleObject;
    textareaStyle: SystemStyleObject;
}
const getSx = ({ inputStyle, buttonStyle, formErrorStyle, formLabelStyle, selectStyle, textareaStyle }: IComponentStyle): SystemStyleObject => ({
    w: "100% !important",

    ".mktoFormRow": { mb: 3 },
    label: {
        ...formLabelStyle,
        display: "block",
        minW: "100%",
        mb: 1,
        ".mktoAsterix": { display: "none" },
    },
    ".mktoError": formErrorStyle,
    "input:not([type=checkbox], [type=radio])": {
        ...removeInvalidRule(inputStyle),
        minW: "100%",
    },
    "input[type=checkbox], input[type=radio]": {
        boxSize: 3,
        flexShrink: 0,
        "&:checked": { filter: "saturate(0)" },
        "+ label": {
            fontWeight: "normal",
            minW: "calc(100% - 24px)",
            m: 0,
            pl: 1,
        },
    },
    ".mktoRadioList, .mktoCheckboxList": {
        display: "grid",
        columnGap: "8px",
        rowGap: "16px",
        gridTemplateColumns: "auto 1fr",
        minW: "100%",
    },
    textarea: {
        ...removeInvalidRule(textareaStyle),
        minW: "100%",
    },
    select: {
        ...removeInvalidRule(selectStyle),
        backgroundImage: selectCaret,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 16px center",
        backgroundSize: "16px 12px",
        minW: "100%",
    },
    ".mktoButtonWrap": { ml: "0 !important" },
    button: {
        ...buttonStyle,
        mt: 2,
    },
    ".mktoInstruction": {
        marginTop: "4px",
        display: "block",
    },
    ".mktoInstruction:empty": {
        display: "none",
    },
});

export interface IMarketoModuleProps extends ITitleAndDescription {
    /** URL to the Marketo server instance for your subscription */
    baseUrl: string;
    /** Munchkin ID of the subscription */
    munchkinId: string;
    /** The form version id (Vid) of the form to load */
    formId: number;
}

export const MarketoModule = ({ title, description, baseUrl, munchkinId, formId }: IMarketoModuleProps) => {
    const buttonStyle = useStyleConfig("Button");
    const formLabelStyle = useStyleConfig("FormLabel", {});
    const textareaStyle = useStyleConfig("Textarea");
    const { field: inputStyle } = useMultiStyleConfig("Input", {});
    const { field: selectStyle } = useMultiStyleConfig("Select", {});
    const { text: formErrorStyle } = useMultiStyleConfig("FormError", {});
    const [isLoading, setIsLoading] = useBoolean(true);

    const onMarketoLoaded = () => {
        MktoForms2.loadForm(baseUrl, munchkinId, formId);
    };

    useEffect(() => {
        const handleIframeLoad = () => {
            setIsLoading.off();
        };

        const handleIframeMessage = (event: MessageEvent) => {
            const { data, origin } = event;

            if (origin.includes(baseUrl) && data.includes(`"mktoReady":true`)) {
                handleIframeLoad();
            }
        };

        window.addEventListener("message", handleIframeMessage);

        return () => {
            window.removeEventListener("message", handleIframeMessage);
        };
    }, [baseUrl, setIsLoading]);

    return (
        <ContainerSmall>
            <Head>
                {/*
                    The marketo js loads automatically the CSS files. Currently there is no way to prevent this behavior (programmatically)
                    Therefore we are adding those `<link>` with this specific id in order to prevent css loading.

                    See: view-source:https://app-lon06.marketo.com/js/forms2/js/forms2.js line: 7342 7346
                */}
                <link id="mktoForms2ThemeStyle"></link>
                <link id="mktoForms2BaseStyle"></link>
            </Head>
            <Script src="//app-lon06.marketo.com/js/forms2/js/forms2.min.js" strategy="lazyOnload" onLoad={onMarketoLoaded} />
            <ModulePrimaryHeadline textAlign={["left", "center"]} mb={3}>
                {title}
            </ModulePrimaryHeadline>
            <Text fontSize="xl" textAlign={["left", "center"]} mb={[3, 3, 5]}>
                {description}
            </Text>
            {isLoading && <MarketoSkeleton />}
            <chakra.form
                id={`mktoForm_${formId}`}
                sx={getSx({ inputStyle, buttonStyle, formErrorStyle, formLabelStyle, selectStyle, textareaStyle })}
                style={{ visibility: isLoading ? "hidden" : "visible" }}
            />
        </ContainerSmall>
    );
};
