export const ORGANIZATION_SCHEMA = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.wikifolio.com",
    logo: "https://wikifoliostorage.blob.core.windows.net/prod/images/wikifolio-logo.png",
    foundingDate: "2012",
    founder: {
        "@type": "Person",
        name: "Andreas Kern",
    },
    address: {
        "@type": "PostalAddress",
        streetAddress: "Berggasse 31",
        addressLocality: "Vienna",
        postalCode: "1090",
        addressCountry: "Austria",
    },
};
