// eslint-disable-next-line no-restricted-imports
import { useContext } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { MixEtfWikifolioRangeContext } from "src/components/modules/mix-etf-with-wikifolio-module/context/mix-etf-wikifolio-range-context";
import { WikifolioEtfSlider } from "./components/slider/wikifolio-etf-slider";
import { DEFAULT_MIX_ETF_WIKIFOLIO_RANGE } from "./constants";

interface IStep2Section extends BoxProps {
    sectionTitle: string;
    isLoading: boolean;
}

export const Step2Section = ({ onReset, sectionTitle, title, isLoading, ...boxProps }: IStep2Section) => {
    const context = useContext(MixEtfWikifolioRangeContext);

    return (
        <Box as="section" {...boxProps}>
            <Text fontSize="lg" fontWeight="bold" as="h3" pb={2}>
                {sectionTitle}
            </Text>
            <WikifolioEtfSlider
                defaultValue={DEFAULT_MIX_ETF_WIKIFOLIO_RANGE}
                isDisabled={isLoading}
                onChange={value => context.setSliderRange(value)}
                isPercentagesInverted
            />
        </Box>
    );
};
