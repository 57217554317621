import { useEffect } from "react";
import { useBoolean } from "@chakra-ui/react";
import Script from "next/script";
import { Container } from "src/components/layout/container";
import { JobsWidgetSkeleton } from "./jobs-widget-skeleton";

const WIDGET_ADDRESS = "https://wikifolio.onlyfy.jobs";

export interface IJobsWidgetModuleProps {
    widgetUrl: string;
}

export const JobsWidgetModule = ({ widgetUrl }: IJobsWidgetModuleProps) => {
    const [isLoading, setIsLoading] = useBoolean(true);

    useEffect(() => {
        const handleIframeLoad = () => {
            setIsLoading.off();
        };

        const handleIframeMessage = (event: MessageEvent) => {
            const { data, origin } = event;

            if (origin === WIDGET_ADDRESS && data.includes("ready")) {
                handleIframeLoad();
            }
        };

        window.addEventListener("message", handleIframeMessage);

        return () => {
            window.removeEventListener("message", handleIframeMessage);
        };
    }, [setIsLoading]);

    return (
        <Container>
            {isLoading && <JobsWidgetSkeleton />}
            <div id="psJobWidget" style={{ visibility: isLoading ? "hidden" : "visible" }} />
            <Script src={widgetUrl} strategy="lazyOnload" />
        </Container>
    );
};
