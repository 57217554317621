import { Stack, StackDivider } from "@chakra-ui/react";
import { Container } from "../../layout/container";
import { IListItem, ListItem } from "./list-item";

export interface IListModuleProps {
    items: IListItem[];
}

export const ListModule = (props: IListModuleProps) => (
    <Container>
        <Stack spacing={[5, 5, 7]} pb={[5, 5, 7]} divider={<StackDivider borderColor="gray.200" />}>
            {props.items.map((itemProps, index) => (
                <ListItem key={index} {...itemProps} />
            ))}
        </Stack>
    </Container>
);
