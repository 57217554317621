import { Fragment } from "react";
import { Flex, HStack, Skeleton, Stack } from "@chakra-ui/react";
import { Container } from "src/components/layout/container";

const Filter = () => (
    <>
        <HStack paddingBottom="2px" display={["none", "none", "flex"]}>
            <Skeleton h={4} w="268px" rounded="4px" />
            <Skeleton h={4} flex={1} rounded="4px" />
        </HStack>
        <Stack pb={1} display={["flex", "flex", "none"]}>
            <Skeleton h={4} rounded="4px" />
            <Skeleton h={4} rounded="4px" />
        </Stack>
    </>
);

const Separator = () => (
    <HStack pb="0px">
        <Skeleton h="1px" flex={1} rounded="4px" />
    </HStack>
);

interface IRow {
    desktopItemsWidth: string[];
    tabletItemsWidth: string[];
    mobileItemsWidth: string[];
    isHeader: boolean;
}

const Row = ({ desktopItemsWidth, tabletItemsWidth, mobileItemsWidth, isHeader }: IRow) => (
    <>
        <HStack h={isHeader ? 7 : 5} display={["none", "none", "flex"]}>
            <Flex w="48.4%">
                <Skeleton h={2} w={desktopItemsWidth[0]} rounded="4px" />
            </Flex>
            <Flex w="51.6%">
                <Container h={2} w="62.6%">
                    <Skeleton h={2} w={desktopItemsWidth[1]} rounded="4px" />
                </Container>
                <Container h={2} w="37.4%" rounded="4px">
                    <Skeleton h={2} w={desktopItemsWidth[2]} rounded="4px" />
                </Container>
            </Flex>
        </HStack>
        <HStack h={isHeader ? 7 : 5} display={["flex", "flex", "none"]}>
            <Flex w="60%">
                <Skeleton h={2} minW={[mobileItemsWidth[0], tabletItemsWidth[0]]} rounded="4px" />
            </Flex>
            <Flex w="30%">
                <Container h={2} w="60%">
                    <Skeleton h={2} w={[mobileItemsWidth[1], tabletItemsWidth[1]]} rounded="4px" />
                </Container>
            </Flex>
        </HStack>
    </>
);

const Footer = () => (
    <HStack justifyContent="center" alignItems="center" h={6} pt={2} mb={[0, 0.5, 1]}>
        <Skeleton h={2} w={[9]} rounded="4px" />
    </HStack>
);

export const JobsWidgetSkeleton = () => {
    const rows = [
        { desktopItemsWidth: ["88px", "44px", "98px"], tabletItemsWidth: ["233px", "44px"], mobileItemsWidth: ["88px", "44px"] },
        { desktopItemsWidth: ["426px", "64px", "80px"], tabletItemsWidth: ["375px", "64px"], mobileItemsWidth: ["148px", "64px"] },
        { desktopItemsWidth: ["345px", "64px", "80px"], tabletItemsWidth: ["304px", "64px"], mobileItemsWidth: ["120px", "64px"] },
        { desktopItemsWidth: ["372px", "64px", "80px"], tabletItemsWidth: ["327px", "64px"], mobileItemsWidth: ["129px", "64px"] },
        { desktopItemsWidth: ["314px", "64px", "80px"], tabletItemsWidth: ["276px", "64px"], mobileItemsWidth: ["109px", "64px"] },
    ];
    return (
        <Flex direction="column" boxShadow="base" rounded="8px" p={[1, 1, 1]} id="jobs-widget-skeleton">
            <>
                <Filter />
                {rows.map((row, index) => {
                    return (
                        <Fragment key={`jobs-widget-skeleton-row-${index}`}>
                            <Row {...row} isHeader={index === 0} />
                            <Separator />
                        </Fragment>
                    );
                })}
                <Footer />
            </>
        </Flex>
    );
};
