import { BoxProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ISearchUnderlying } from "src/api/client/search-autocomplete.api";
import { useQueryWithSearchResults } from "src/components/common/typeahead/hooks/use-query-with-search-results";
import { ISearchResultsMutationVariables } from "src/components/common/typeahead/hooks/use-search-results";
import { UnderlyingSearchResults } from "src/components/common/typeahead/results/underlying-search-results";
import { Typeahead } from "src/components/common/typeahead/typeahead";
import { EnumSecurityType } from "src/types/common-enum";

const ETF_SEARCH_MUTATION_VARIABLES: ISearchResultsMutationVariables = {
    wikifoliosCount: 0,
    underlyingsCount: 10,
    securityTypes: [EnumSecurityType.ETF],
};

interface IEtfTypeaheadWrapper extends Pick<BoxProps, "width" | "py"> {
    placeholderText: string;
    onSelect?: (underlying: ISearchUnderlying) => void;
    onSearchFocus: () => void;
    enableAutoFocus?: boolean;
}

export const EtfTypeaheadWrapper = ({ onSelect, onSearchFocus, placeholderText, enableAutoFocus, ...boxProps }: IEtfTypeaheadWrapper) => {
    const { query, setQuery, results, combinedResults, onClear } = useQueryWithSearchResults(ETF_SEARCH_MUTATION_VARIABLES);
    const { t } = useTranslation("mix-etf-with-wikifolio-module");

    return (
        <Typeahead
            placeholderText={placeholderText}
            query={query}
            enableAutoFocus={enableAutoFocus}
            combinedResults={combinedResults}
            onFocus={onSearchFocus}
            onSelect={item => onSelect?.(item as ISearchUnderlying)}
            setQuery={setQuery}
            onClear={onClear}
            {...boxProps}
        >
            {({ isOpen, selectedIndex, setOpen }) =>
                isOpen &&
                results && (
                    <UnderlyingSearchResults
                        onClick={(ev, underlying) => {
                            ev.preventDefault();
                            setOpen.off();
                            onSelect?.(underlying);
                        }}
                        notFoundMessage={t("labels.not-found")}
                        notFoundMessageBoxProps={{ pb: 0 }}
                        results={results}
                        selectedIndex={selectedIndex}
                        query={query}
                    />
                )
            }
        </Typeahead>
    );
};
