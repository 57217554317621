import { Badge, Box, Stack, Flex } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { Comment } from "src/components/comments/comment";
import { IArticlePreview } from "src/components/common/article-preview/article-preview.types";
import { ExpandableText } from "src/components/common/expandable-text";
import { ModulePrimaryHeadline, PrimaryHeadline } from "src/components/common/module-headlines";
import { RichText } from "src/components/common/rich-text/rich-text";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { Bestseller } from "src/components/modules/bestseller-module/bestseller";
import { IBestsellerData } from "src/components/modules/bestseller-module/bestseller.types";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { ILink } from "src/types/common";

interface IComment {
    articlePreview?: IArticlePreview;
    message: string;
}

export interface IAutoContentModuleProps {
    wikifolioCard: IBestsellerData;
    introTitle: string;
    introDescription: string | null;
    introLink: ILink;
    title: string;
    description: string;
    comment: IComment | null;
    autoContentDescription: string | null;
    /* Security info box */
    securityStatus: {
        description: string;
        percentage: number;
    } | null;
}

export const AutoContentModule = ({
    wikifolioCard,
    autoContentDescription,
    title,
    description,
    comment,
    securityStatus,
    introTitle,
    introLink,
    introDescription,
}: IAutoContentModuleProps) => {
    const { formatPerformance } = useNumberFormatter();

    return (
        <Container>
            <PrimaryHeadline titleAsH1 mb={[5, 5, 4, 7]}>
                {introTitle}
            </PrimaryHeadline>
            {autoContentDescription && (
                <Flex mb={2} flexFlow={["column", "row", "row", "row"]}>
                    <Box maxWidth={["100%", "100%", "640px"]} flex={1} boxShadow="base" rounded="8px" p={2} bg="white" borderRadius="8px">
                        <RichText fontSize="md" text={autoContentDescription} />
                    </Box>

                    {securityStatus && (
                        <Box
                            w={["100%", "182px", "182px", "271px"]}
                            ml={[0, 2]}
                            mt={[2, 0]}
                            px={[2, 2, "inherit"]}
                            py={2}
                            boxShadow="base"
                            rounded="8px"
                            borderRadius="8px"
                            display="flex"
                            flexDirection={["row", "column"]}
                            justifyContent={["flex-start", "center"]}
                            alignItems="center"
                            flexWrap="wrap"
                            bg="white"
                        >
                            <Badge pr={1} pl={1} variant="solid" colorScheme="green">
                                {formatPerformance(securityStatus.percentage)}
                            </Badge>
                            <RichText
                                pl={[2, 0, 0]}
                                fontSize={["md", "md", "sm"]}
                                pt={[0, 0, "4px"]}
                                alignSelf="center"
                                text={securityStatus.description}
                            />
                        </Box>
                    )}
                </Flex>
            )}
            {introDescription && <SimpleRichText mt={[3, 3, 4]} mb={4} fontSize={["md", "md", "xl"]} text={introDescription} />}
            {introLink.url && (
                <Box textAlign="right" mb={5}>
                    <ArrowLink href={introLink.url}>{introLink.name}</ArrowLink>
                </Box>
            )}
            <Stack direction={["column-reverse", "row"]} spacing={[3, 5, 6, 10]}>
                <Box justifyContent="center" display="flex">
                    <Bestseller
                        alignSelf="baseline"
                        buttonsDirection={["column", "column", "column", "row-reverse"]}
                        data={wikifolioCard}
                        w={["100%", "297px", "297px", "358px"]}
                        maxW={["312px", "initial"]}
                        bg="white"
                    />
                </Box>
                <Box maxWidth={["initial", "332px", "initial"]}>
                    <Box wordBreak="break-all">
                        <ModulePrimaryHeadline pt={2}>{title}</ModulePrimaryHeadline>
                        {/* backend provides either description or comment for ExpandableText */}
                        {description && <ExpandableText formattingType="umbracoHtml" noOfLines={16} mt={2} mb={[2, 4]} text={description} />}
                        {comment && <Comment noOfLines={8} {...comment} mt={2} />}
                    </Box>
                </Box>
            </Stack>
        </Container>
    );
};
