import { Box, Text } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { HorizontalScrollSnapCards } from "src/components/common/horizontal-scroll-snap-cards/horizontal-scroll-snap-cards";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { IBestsellerData } from "src/components/modules/bestseller-module/bestseller.types";
import { ILink } from "src/types/common";
import { Bestseller } from "./bestseller";

const THREE_BESTSELLER_MODULE_TEST_ID = "three-bestseller-module";

export interface IThreeBestsellerModuleProps {
    title: string;
    description: string;
    wikifolios: IBestsellerData[];
    more?: ILink;
}

export const ThreeBestsellerModule = ({ title, description, wikifolios, more }: IThreeBestsellerModuleProps) => (
    <Container data-test-id={THREE_BESTSELLER_MODULE_TEST_ID}>
        <ModulePrimaryHeadline mb={[3]}>{title}</ModulePrimaryHeadline>
        <Text fontSize="lg" mb={[4, 4, 4, 5]}>
            {description}
        </Text>
        <HorizontalScrollSnapCards cardsData={wikifolios} Card={Bestseller} />
        {more && (
            <Box textAlign={["left", "right"]} mt={5}>
                <ArrowLink href={more.url} target={more.target} colorScheme="green">
                    {more.name}
                </ArrowLink>
            </Box>
        )}
    </Container>
);
