import { Box } from "@chakra-ui/react";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { IImage } from "src/types/common";

export const LeftImg = ({ image }: { image: IImage }) => (
    <Box
        pos={["absolute"]}
        display={["none", "none", "block"]}
        top={0}
        left={0}
        w={[null, null, "50.5%", "49%"]}
        h="100%"
        maxW="720px"
        overflow="hidden"
    >
        <ResponsiveImage
            objectFit="cover"
            objectPosition="center"
            height="100%"
            width="100%"
            maxWidth="none"
            srcSet={[{}, {}, { queryParams: { w: 496 } }, { queryParams: { w: 720 } }]}
            baseUrl={image.url}
            alt={image.alt}
        />
    </Box>
);
