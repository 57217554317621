import { Flex, FlexProps, Box, Text } from "@chakra-ui/react";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { IconFilledQuote } from "src/components/icons";
import { HOVER_STYLE } from "src/theme/hover-style";
import { IImage, ILink } from "src/types/common";

export interface IQuoteCardProps extends FlexProps {
    quote: string;
    companyImage?: IImage;
    trader?: {
        name: string;
        designation: string;
        avatar?: IImage;
    };
    link?: ILink;
}

export const QuoteCard = ({ quote, trader, companyImage, link, ...flexProps }: IQuoteCardProps) => (
    <Flex
        flexDir="column"
        align="center"
        bg="white"
        borderRadius="8px"
        boxShadow="base"
        py={[3, 3, 4]}
        px={[2, 4, 4]}
        {...(link && { ...HOVER_STYLE, as: "a", href: link.url, target: link.target })}
        {...flexProps}
    >
        <Box mb={3}>
            {companyImage ? (
                <ResponsiveImage baseUrl={companyImage.url} alt={companyImage.alt} h="32px" w="auto" srcSet={[{ queryParams: { h: 32 } }]} />
            ) : (
                <IconFilledQuote boxSize={6} color="green.300" />
            )}
        </Box>
        <Text fontSize="md" lineHeight="base" textAlign="center" flexGrow={1}>
            {quote}
        </Text>
        {trader && (
            <Flex mx="auto" mt={3} align="center">
                {trader.avatar && (
                    <ResponsiveImage
                        mr={1}
                        borderRadius="50%"
                        baseUrl={trader.avatar.url}
                        alt={trader.avatar.alt}
                        boxSize="48px"
                        srcSet={[{ queryParams: { w: 48, h: 48 } }]}
                    />
                )}
                <Box textAlign={trader.avatar ? "left" : "center"}>
                    <Text fontSize="lg" fontWeight="bold" lineHeight="tall">
                        {trader.name}
                    </Text>
                    <Text fontSize="sm" lineHeight="base">
                        {trader.designation}
                    </Text>
                </Box>
            </Flex>
        )}
    </Flex>
);
