import { useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { ISearchUnderlying } from "src/api/client/search-autocomplete.api";
import { GlobalNavSearchUi } from "src/components/layout/global-nav-search/global-nav-search-ui";
import { EtfTypeaheadWrapper } from "./etf-typeahead-wrapper";

interface IEtfSearchProps {
    onSelect?: (underlying: ISearchUnderlying) => void;
    placeholderText: string;
}

export const EtfSearch = ({ onSelect, placeholderText }: IEtfSearchProps) => {
    const isMobileOrTablet = useBreakpointValue([true, true, false]);
    const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();

    const renderOverlaySearch = isMobileOrTablet && isSearchOpen;

    const onSearchFocus = () => {
        if (isMobileOrTablet) {
            onSearchOpen();
        }
    };

    return (
        <>
            {renderOverlaySearch && (
                <GlobalNavSearchUi navHeights={[0]} onSearchClose={onSearchClose}>
                    <EtfTypeaheadWrapper onSelect={onSelect} onSearchFocus={onSearchFocus} placeholderText={placeholderText} enableAutoFocus />
                </GlobalNavSearchUi>
            )}
            <EtfTypeaheadWrapper onSelect={onSelect} onSearchFocus={onSearchFocus} placeholderText={placeholderText} width="100%" py={0.5} />
        </>
    );
};
