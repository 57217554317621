import { Box, BoxProps, Divider, Flex, HStack, Skeleton, SkeletonCircle, Stack, Text } from "@chakra-ui/react";

interface IWikifolioCard extends BoxProps {}

export const MixEtfWithWikifolioCardSkeleton = ({ ...boxProps }: IWikifolioCard) => {
    return (
        <Box flex={1} boxShadow="base" rounded="8px" p={[2, 3]} {...boxProps}>
            <HStack spacing={1}>
                <Flex flexShrink={0}>
                    <SkeletonCircle flexShrink={0} size="6" />
                    <SkeletonCircle flexShrink={0} size="6" marginLeft={-1} />
                </Flex>
                <Flex flexDir="column" flexGrow={1} overflow="hidden">
                    <Skeleton w={["157px", "197px"]} h={2} mb={1} />
                    <Skeleton w="125px" h={2} />
                </Flex>
            </HStack>

            <Skeleton w="105px" h={2} mt={3} />
            <Stack direction="column" justify="space-between" align="flex-start" spacing={0}>
                <Flex width="100%" py={2} justifyContent="space-between">
                    <Skeleton w="60px" h={2} />
                    <Skeleton w="60px" h={2} />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={2} justifyContent="space-between">
                    <Skeleton w="60px" h={2} />
                    <Skeleton w="60px" h={2} />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Skeleton w="60px" h={2} />
                    <Skeleton w="60px" h={2} />
                </Flex>
                <Divider borderColor="gray.100" />
            </Stack>

            <Text as="h5" fontWeight="bold" pt={3}>
                <Skeleton w="105px" h={2} />
            </Text>
            <Stack direction="column" justify="space-between" align="flex-start" spacing={0}>
                <Flex width="100%" py={2} justifyContent="space-between">
                    <Skeleton w="60px" h={2} />
                    <Skeleton w="60px" h={2} />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Skeleton w="60px" h={2} />
                    <Skeleton w="60px" h={2} />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={2} justifyContent="space-between">
                    <Skeleton w="60px" h={2} />
                    <Skeleton w="60px" h={2} />
                </Flex>
                <Divider borderColor="gray.100" />
            </Stack>

            <Skeleton rounded="4px" w="full" h={5} mt={3} />
        </Box>
    );
};
