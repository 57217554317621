import { Box, Flex, Text, AspectRatio } from "@chakra-ui/react";
import { ILink, IImage } from "src/types/common";
import { ArrowLink } from "../../base/arrow-link";
import { ResponsiveImage } from "../../base/responsive-image";
import { WfHeading } from "../../base/wf-heading";
import { WfLink, WfLinkGhost } from "../../base/wf-link";
import { SimpleRichText } from "../../common/rich-text/simple-rich-text";

const IMAGE_WIDTH = ["100%", "277px", "382px", "450px"];

export interface IListItem {
    title: string;
    description: string;
    date?: string;
    image?: IImage;
    link?: ILink;
}

const Image = ({ image, link }: IListItem) => {
    if (!image) {
        return null;
    }

    const renderedImage = (
        <AspectRatio mb={[2, 0]} w={IMAGE_WIDTH} ratio={3 / 2}>
            <ResponsiveImage
                borderRadius="4px"
                w="100%"
                h="auto"
                srcSet={[
                    { queryParams: { w: 312, h: 208, fit: "crop" } },
                    { queryParams: { w: 277, h: 185, fit: "crop" } },
                    { queryParams: { w: 382, h: 255, fit: "crop" } },
                    { queryParams: { w: 450, h: 300, fit: "crop" } },
                ]}
                baseUrl={image.url}
                alt={image.alt}
            />
        </AspectRatio>
    );
    if (!link) {
        return renderedImage;
    }

    return (
        <WfLink display="block" href={link.url} target={link.target}>
            {renderedImage}
        </WfLink>
    );
};

const Title = ({ title, link }: IListItem) =>
    link ? (
        <WfLinkGhost display="block" href={link.url} target={link.target}>
            {title}
        </WfLinkGhost>
    ) : (
        <>{title}</>
    );

export const ListItem = (props: IListItem) => {
    const { description, date, link } = props;

    return (
        <Flex w="100%" flexDir={["column", "row-reverse"]}>
            <Box flexShrink={0} minW={IMAGE_WIDTH} maxW={IMAGE_WIDTH}>
                <Image {...props} />
            </Box>
            <Box flexGrow={1} mr={[0, 3, 5, 10]}>
                {date && (
                    <Text fontSize={["sm", "sm", "sm", "md"]} fontWeight={["semibold", "semibold", "semibold", "normal"]} color="gray.300" mb={1}>
                        {date}
                    </Text>
                )}
                <WfHeading as="h2" fontSize={["xl", "xl", "2xl", "3xl"]} mb={[2, 2, 2, 3]}>
                    <Title {...props} />
                </WfHeading>
                <SimpleRichText fontSize={["md", "md", "lg"]} text={description} />
                {link && (
                    <ArrowLink display="inline-block" href={link.url} target={link.target} mt={2} colorScheme="green">
                        {link.name}
                    </ArrowLink>
                )}
            </Box>
        </Flex>
    );
};
