import { Box, HStack, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Button } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { IconSuccess } from "src/components/icons";
import { Container } from "src/components/layout/container";

interface IChallengeFormSuccessMessageProps {
    heading: string;
    subHeading: string;
    firstButtonLabel: string;
    firstButtonUrl: string;
    secondButtonLabel: string;
    secondButtonUrl: string;
    listItems?: string[];
}

export const ChallengeFormSuccessMessage = ({
    heading,
    subHeading,
    firstButtonUrl,
    firstButtonLabel,
    secondButtonUrl,
    secondButtonLabel,
    listItems,
}: IChallengeFormSuccessMessageProps) => {
    return (
        <Container textAlign="center">
            <WfHeading as="h1" fontSize="4xl">
                {heading}
            </WfHeading>
            <Text mt={2} fontSize="lg">
                {subHeading}
            </Text>
            <IconSuccess fill="transparent" mt={7} mb={4} w="130px" h="130px" />
            <Box w="100%" maxWidth="570px" mx="auto" mb={4}>
                <UnorderedList spacing={2} textAlign="left">
                    {listItems?.map((item, index) => (
                        <ListItem key={index}>{item}</ListItem>
                    ))}
                </UnorderedList>
            </Box>
            <HStack justifyContent="center" wrap="wrap" spacing={[2, 4]}>
                <Button w={["100%", "auto"]} variant="outline" as="a" href={firstButtonUrl}>
                    {firstButtonLabel}
                </Button>
                <Button w={["100%", "auto"]} colorScheme="green" as="a" href={secondButtonUrl}>
                    {secondButtonLabel}
                </Button>
            </HStack>
        </Container>
    );
};
