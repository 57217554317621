const ctaModuleColorSchemeMap = {
    green: {
        bg: "green.200",
    },
    gray: {
        bg: "gray.50",
    },
};

export type CtaModuleColorScheme = keyof typeof ctaModuleColorSchemeMap;

const CTA_MODULE_DEFAULT_COLOR_SCHEME: CtaModuleColorScheme = "green";

export const getCtaModuleColorScheme = (key: CtaModuleColorScheme | undefined) => ctaModuleColorSchemeMap[key ?? CTA_MODULE_DEFAULT_COLOR_SCHEME];
