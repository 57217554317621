import { Box, Flex } from "@chakra-ui/react";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { MultiCta } from "src/components/common/cta/cta";
import { IMultiCtaProps } from "src/components/common/cta/cta.types";
import { PrimaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container, ContainerBig } from "src/components/layout/container";
import { IImage } from "src/types/common";

export interface ITextImageModuleProps extends IMultiCtaProps {
    title: string;
    titleAsH1: boolean;
    description: string;
    image?: IImage;
    isFirstModule: boolean;
}

export const TextImageModule = ({ title, titleAsH1, description, image, isFirstModule, ...ctaProps }: ITextImageModuleProps) => (
    <ContainerBig pos="relative">
        <Container py={[0, 10]} pr={{ sm: 1 }}>
            {image && (
                <Flex
                    pos={["relative", "absolute"]}
                    mb={[2, 0]}
                    w={["100%", "384px", "496px", "720px"]}
                    h="100%"
                    left="0px"
                    top="0px"
                    overflow="hidden"
                >
                    <ResponsiveImage
                        objectFit="cover"
                        objectPosition="center"
                        height={["auto", "100%"]}
                        width="100%"
                        maxWidth="none"
                        srcSet={[
                            { queryParams: { w: 768, h: 512, fit: "crop", dpr: 1 } },
                            { queryParams: { w: 384 } },
                            { queryParams: { w: 496 } },
                            { queryParams: { w: 720 } },
                        ]}
                        baseUrl={image.url}
                        alt={image.alt}
                        loading={isFirstModule ? "eager" : "lazy"}
                    />
                </Flex>
            )}
            <Box ml="auto" w={["100%", "637px", "619px", "721px"]} p={[0, 7]} pos="relative" bg="white">
                {title && (
                    <PrimaryHeadline titleAsH1={titleAsH1} mb={[3, 5]}>
                        {title}
                    </PrimaryHeadline>
                )}
                <SimpleRichText mb={3} fontSize={["md", "lg"]} text={description} />
                <MultiCta {...ctaProps} />
            </Box>
        </Container>
    </ContainerBig>
);
