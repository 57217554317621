import { Box, Stack } from "@chakra-ui/react";
import Head from "next/head";
import { ArrowLink } from "src/components/base/arrow-link";
import { IWfAccordionItem, WfAccordion } from "src/components/base/wf-accordion";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { ILabelValue, ILink } from "src/types/common";

// https://developers.google.com/search/docs/data-types/faqpage?hl=en
const structuredData = (questionAnswers: ILabelValue[]) => ({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: questionAnswers.map(q => ({
        "@type": "Question",
        name: q.label,
        acceptedAnswer: {
            "@type": "Answer",
            text: q.value,
        },
    })),
});

export interface IFaqModuleProps {
    title: string;
    questionAnswers: IWfAccordionItem[];
    link?: ILink;
}

export const FaqModule = ({ title, questionAnswers, link }: IFaqModuleProps) => (
    <Container>
        <Head>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
        </Head>
        <Stack direction={["column", "column", "column", "row"]} spacing={5} justify="space-between">
            <ModulePrimaryHeadline w={["100%", "100%", "100%", "323px"]} textAlign={["left", "center", "center", "left"]} pt={[0, 0, 0, 2]}>
                {title}
            </ModulePrimaryHeadline>
            <WfAccordion allowMultiple shouldScrollIntoView data={questionAnswers} size="lg" w={["100%", "100%", "100%", "680px"]} />
        </Stack>
        {link && (
            <Box mt={[4, 5, 6]} textAlign="right">
                <ArrowLink href={link.url} target={link.target} colorScheme="green">
                    {link.name}
                </ArrowLink>
            </Box>
        )}
    </Container>
);
