import { useState } from "react";
import { Box, FormControl, FormLabel, Input, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { HashLink, HashLinkUnstyled } from "src/components/base/hash-link";
import { ThirdPartyAuthButtons, EnumPlacement } from "src/components/common/auth";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container, ContainerBig } from "src/components/layout/container";
import { getGlobals } from "src/consts/globals";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { IPromotionData, useAuthModalStore } from "src/stores/auth-modal-store";
import { IImage } from "src/types/common";
import { LeftImg } from "./components/left-image";
import { RegisterDivider } from "./components/register-divider";

const REGISTER_MODULE_TEST_ID = "register-module";

export interface IRegisterModuleProps {
    title: string;
    image: IImage;
    promotionData?: IPromotionData | null;
    welcomePageNodeId: number | null;
}

export const RegisterModule = ({ title, image, welcomePageNodeId, promotionData = null }: IRegisterModuleProps) => {
    const { t } = useTranslation("common");
    const loginProviders = getGlobals().loginProviders;
    const { buildLoginModalLink, buildRegisterModalLink } = useLinkBuilder();
    const { prefilledRegisterEmail, setPromotionData, setPrefilledRegisterEmail, setShowEmailRegisterForm, setWelcomePageNodeId } =
        useAuthModalStore();
    const [email, setEmail] = useState<string>(prefilledRegisterEmail);

    return (
        <ContainerBig pos="relative" overflow="hidden" data-test-id={REGISTER_MODULE_TEST_ID}>
            <Container display="flex" justifyContent={["center", "center", "flex-end"]}>
                <LeftImg image={image} />
                <Box pos="relative" w={["100%", "500px", "413px", "472px"]} py={[0, 0, 10]}>
                    <ModulePrimaryHeadline mb={[6, 8]} textAlign="center">
                        {title}
                    </ModulePrimaryHeadline>
                    <FormControl id="register-email-initial" mb={2}>
                        <FormLabel>{t("email")}</FormLabel>
                        <Input
                            type="email"
                            placeholder={t("email")}
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                                setPrefilledRegisterEmail(e.target.value);
                            }}
                        />
                    </FormControl>
                    <Button
                        as={HashLinkUnstyled}
                        href={buildRegisterModalLink()}
                        onClick={() => {
                            setEmail("");
                            setShowEmailRegisterForm(true);
                            setPromotionData(promotionData);
                            welcomePageNodeId && setWelcomePageNodeId(welcomePageNodeId);
                        }}
                        colorScheme="green"
                        className="gtm-register"
                        data-gtm-action="register-module"
                        width="100%"
                    >
                        {t("continue")}
                    </Button>
                    {loginProviders?.length && (
                        <>
                            <RegisterDivider />
                            <VStack spacing={2}>
                                <ThirdPartyAuthButtons placement={EnumPlacement.RegisterModule} loginProviders={loginProviders} width="100%" />
                            </VStack>
                        </>
                    )}

                    <Box textAlign="center" mt={[3, 6]}>
                        <Text mb={1}>{t("already-registered")}</Text>
                        <HashLink href={buildLoginModalLink()}>{t("go-to-login")}</HashLink>
                    </Box>
                </Box>
            </Container>
        </ContainerBig>
    );
};
