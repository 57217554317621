import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { Section, SectionProps } from "src/components/base";
import { useNavHeight } from "src/components/layout/hooks/use-nav-height";

interface IAreaOverviewModuleContainerProps extends SectionProps {
    sectionNavigationId?: string | null;
    children: ReactNode;
}

export const AreaOverviewModuleContainer = ({ sectionNavigationId, children, ...sectionProps }: IAreaOverviewModuleContainerProps) => {
    const { NAV_HEIGHT_VARIATION } = useNavHeight();
    const NEGATED_NAV_HEIGHT = NAV_HEIGHT_VARIATION.map(val => -val);

    return (
        <Section {...sectionProps}>
            {sectionNavigationId && (
                <Box position="relative" id={sectionNavigationId} width="0" height="0" visibility="hidden" top={NEGATED_NAV_HEIGHT} />
            )}
            {children}
        </Section>
    );
};
