import { Box, Divider, Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

export const RegisterDivider = () => {
    const { t } = useTranslation("common");

    return (
        <Flex align="center" py={3}>
            <Divider />
            <Box px={3} bg="white">
                {t("or")}
            </Box>
            <Divider />
        </Flex>
    );
};
