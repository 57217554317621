import { useRef } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useRichText } from "src/components/common/rich-text/use-rich-text";

export interface IRichTextProps extends BoxProps {
    text: string;
}
export const RichText = ({ text, ...boxProps }: IRichTextProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const sx = useRichText();

    // Dev-Note: uncomment when we want to use Next.js agnostic links
    // useNextLink(ref);

    return <Box ref={ref} dangerouslySetInnerHTML={{ __html: text }} sx={sx} {...boxProps} />;
};
